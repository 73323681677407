.showcomments {
  background-color: #BFC6FE;
  margin-left: 80px;
  margin-right: 80px;
  height: 75px;
  width: 200px;
  border: none;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  cursor: pointer;
}

.comments-section {
  margin-left: 75px;
  margin-right: 75px;
  animation: fadeInUp;
  animation-duration: 2s;
}
.comments-section h3 {
  font-family: "Belanosima", sans-serif;
  font-size: 30px;
}
.comments-section .comment-editor {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  box-shadow: 0px 5px 10px;
  height: 150px;
  width: 75%;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
}
.comments-section .comment-editor textarea {
  width: 95%;
  height: 95%;
  border: none;
  resize: none;
}
.comments-section .comment-editor textarea:focus {
  border: none !important;
  outline: 0;
}
.comments-section .comment-editor .sendcomment {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #BFC6FE;
  border: 0;
  border-radius: 15px;
}
.comments-section i {
  display: block;
  width: 80%;
}
.comments-section .comment-info {
  animation: bounceIn;
  animation-duration: 2s;
  display: flex;
  width: 75%;
  background-color: #89E79D;
  height: 100px;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 35px;
}
.comments-section .comment-info-error {
  animation: bounceIn;
  animation-duration: 2s;
  display: flex;
  width: 75%;
  background-color: red;
  height: 100px;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 35px;
}
.comments-section .comments-list {
  margin-top: 20px;
  width: 80%;
}
.comments-section .comments-list .comment {
  box-shadow: 0px 5px 10px;
  border: 0;
  border-radius: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-left: 10px;
  padding-bottom: 10px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  font-weight: 700;
  flex-direction: row;
  gap: 10px;
}
.comments-section .comments-list .comment img {
  width: 30px;
  height: 30px;
  border-radius: 1000px;
}

@media (max-width: 600px) {
  .showcomments {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .comments-section {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
}
@media (max-width: 900px) {
  .showcomments {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .comments-section {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
}/*# sourceMappingURL=comments.css.map */