@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");
@font-face {
  font-family: "Quicksand";
  src: local("Quicksand"), url("./fonts/Quicksand.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Belanosima";
  src: local("Belanosima"), url("./fonts/Belanosima.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "JosefinSans";
  src: local("JosefinSans"), url("./fonts/JosefinSans.ttf") format("truetype");
  font-weight: normal;
}
body {
  font-family: "Quicksand", sans-serif;
  margin: 0;
  padding: 0;
  gap: 0;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #BFC6FE;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  animation: spin 2s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 200px;
  /*background-color: #ECECEC;*/
  text-align: center;
}
header h1 {
  font-family: "Belanosima", sans-serif;
  font-size: 60px;
}

.info {
  margin-left: 125px;
  margin-right: 125px;
}

.section-post {
  animation: fadeInUp;
  animation-duration: 2s;
}

.container-list {
  display: flex;
  flex-direction: column;
  margin-left: 125px;
  margin-right: 125px;
}
.container-list .search-section {
  animation: fadeInUp;
  animation-duration: 2s;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 320px;
  height: 50px;
  border-radius: 100px;
  background-color: #BFC6FE;
}
.container-list .search-section input {
  position: relative;
  top: -21px;
  width: 235px;
  height: 40px;
  border: none;
  font-size: 18px;
  font-family: "Quicksand", sans-serif;
  background-color: #BFC6FE;
}
.container-list .search-section input:focus {
  outline: none;
}
.container-list .posts {
  animation: fadeInUp;
  animation-duration: 2s;
}
.container-list .posts h3 {
  text-align: center;
  font-size: 30px;
  font-family: "Belanosima", sans-serif;
}
.container-list .posts .posts-grid {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  gap: 10px;
}
.container-list .posts .posts-grid .post {
  border-radius: 50px;
  box-shadow: 0px 3px 3px;
  height: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  background-color: #BFC6FE;
}
.container-list .posts .posts-grid .post h4 {
  font-family: "JosefinSans", sans-serif;
  font-size: 25px;
  text-align: center;
}
.container-list .posts .posts-grid .post h4 a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.container-list .posts .posts-grid .post h4 a:hover {
  -webkit-text-decoration: solid !important;
          text-decoration: solid !important;
}
.container-list .posts .posts-grid .post .enter-button {
  float: right;
  vertical-align: bottom;
  margin-right: 10px;
  background-color: #001BFF;
  border-radius: 15px;
  border: none;
  width: 50px;
  height: 50px;
  text-align: center;
  color: white;
  cursor: pointer;
}
.container-list .posts .pagination {
  display: block;
  list-style-type: none;
  animation: fadeInUp;
  animation-duration: 2s;
}
.container-list .posts .pagination li {
  display: inline;
  white-space: nowrap;
}
.container-list .posts .pagination .page-item {
  color: black;
  background-color: white;
  width: 35px;
  height: 35px;
  border: 1px solid black;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
}
.container-list .posts .pagination .page-item button {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  background-color: white;
  border: none;
}
.container-list .posts .pagination .active {
  background-color: #BFC6FE !important;
}
.container-list .posts .pagination .active button {
  background-color: #BFC6FE !important;
}

.container-post {
  display: grid;
  grid-template-columns: 75% 25%;
  margin-left: 80px;
  margin-right: 80px;
  gap: 10px;
}
.container-post .post-content h1 {
  font-family: "JosefinSans", sans-serif;
  font-size: 40px;
}
.container-post .post-content p {
  text-align: justify;
  font-size: 18px;
}
.container-post .post-content img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.container-post .featured-posts h3 {
  font-family: "JosefinSans", sans-serif;
  font-size: 20px;
}
.container-post .featured-posts .featured-post a {
  text-decoration: none;
  color: black;
  font-family: "JosefinSans", sans-serif;
  font-size: 18px;
  cursor: pointer;
}
.container-post .featured-posts .featured-post a:hover {
  -webkit-text-decoration: 2px solid black !important;
          text-decoration: 2px solid black !important;
}
.container-post .featured-posts .featured-post small {
  font-size: 10px;
}

footer {
  padding: 30px;
}
footer .footer {
  text-align: center;
  font-weight: 700;
}

@media (max-width: 900px) {
  .container-list {
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .container-list .posts-grid {
    grid-template-columns: 50% 50% !important;
  }
  .container-post {
    grid-template-columns: 100% !important;
    margin-left: 75px !important;
    margin-right: 75px !important;
  }
  .featured-posts {
    margin: 0 auto !important;
  }
  .featured-posts .featured-post {
    text-align: center;
  }
}
@media (max-width: 600px) {
  .container-list {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .container-list .search-section {
    width: 225px !important;
  }
  .container-list .search-section .search-bar {
    width: 150px !important;
  }
  .container-list .posts-grid {
    grid-template-columns: 100% !important;
  }
  .container-post {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
}/*# sourceMappingURL=App.css.map */